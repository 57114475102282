"use client";
// src/useStore.js
import { create } from "zustand";
const translations = {
  en: {
    navbar: {
      home: "Home",
      features: "Features",
      howitworks: "How it works",
      download: "Download",
      signup: "Sign Up",
      login: "Login",
      Calculator: "Calculator",
      lang: "عربي",
    },
    section1: {
      unlock: "Unlock",
      potential: "the Potential of",
      gold: "Gold.",
      text: "Our user-friendly app makes investing in gold simple and secure.",
      text2:
        "Manage your gold fractions directly from our secure and user-friendly web platform.",
      download: "Download the App",
      StartYourJourney: "Start Your Journey.",
      goldPriceUpdate: "Gold price updated at",
      buy: "Buy - 24K ",
      sell: "Sell - 24K ",
      egp: "EGP",
      goldBuy: "Gold 24K Buy Price",
      goldSell: "Gold 24K Sell Price",
    },
    section2: {
      StayTunedfor: "Stay Tuned for",
      MoreWays: "More Ways",
      to: "To",
      ManageGold: "Manage Gold!",
      why: "Why",
      sabika: "Sabika?",
      buy: "Buy & Sell gold",
      fraction: "Fractions",
      GoldFractions: "Gold Fractions",
      Accessgoldmarkets: "Access gold markets",
      withease: "with ease",
      Realtime: "Real-time",
      PortfolioTracking: "Portfolio Tracking",
      yourinvestments: "your investments",
      anytime: "anytime",
      SecureWalletManagement: "Secure Wallet Management",
      Keepsafe: "Keep your assets safe with robust",
      Monitor: "Monitor",
      security: "security",
      track: "Track",
      your: "Your Investment",
      portfolio: "Portfolio.",
      secure: "Secure",
      transaction: "Transactions & Wallet",
      management: "Management.",
      enjoy: "Enjoy a",
      smoothandseamless: "smooth and seamless",
      experienceon: "experience on",
      Sabika: "Sabika",
      seamless: "Seamless Experience.",
    },
    section3: {
      More: "More",
      Coming: "Coming",
      what: "What we",
      offer: "Offer?",
      lowest: "Lowest Price",
      market: "in the Market!",
      Competitive: "Competitive",
      Price: "Price",
      effort: "Own a piece of the gold market at unbeatable prices.",
      user: "User-friendly",
      app: "interface.",
      sabika: "Sabika",
      makes:
        "Easily navigate Sabika’s features and manage your investments without hassle.",
    },
    section4: {
      Register: "Register",
      RegDesc:
        "Create your account securely on our website and get started with ease.",
      DepositFunds: "Deposit Funds",
      DepositDesc:
        "Add money to your wallet securely and manage it without hassle.",
      ManageGold: "Manage Gold",
      ManageGoldDesc:
        "Buy and sell gold fractions smoothly using your personal dashboard.",
      how: "How do I",
      start: "start?",
      download: "Download",
      downloadText: "Download the Sabika app from your app store.",
      signup: "SignUp",
      signupText: "Create an account to begin trading.",
      deposit: "Deposit Funds",
      depositText: "Add funds to start investing in gold.",
      trade: "Trade Gold",
      tradeText: "Start trading gold with ease.",
    },
    section5: {
      take: "Take",
      control: "Control",
      of: "of Your",
      financial: "Financial Future",
      with: "with",
      sabika: "Sabika!",
    },
    footer: {
      AboutUs: "About Us",
      privacy: "Privacy Policy",
      faq: "FAQ",
      Contactus: "Contact us",
      terms: "Terms And Conditions",
    },
    header: {
      wallet: "Wallet",
      portfolio: "Portfolio",
      dashboard: "Dashboard",
      account: "Account",
      language: "Language",
      logout: "Logout",
    },
    wallet: {
      total: "Total Balance",
      totalToolTip: " This is the total balance of your wallet",
      available: "Available",
      availableToolTip:
        "This is the total amount of money you have in your wallet",
      availableWithDraw: "Available for Withdrawal",
      availableWithDrawToolTip:
        "This is the total amount of money you can withdraw",
      processing: "Processing",
      processingToolTip:
        "This is the total amount of money that is currently processing",
      totalStorageFees: "Total Storage Fees",
      totalStorageFeesToolTip:
        "This is the total amount of money you have paid for storage fees",
      hide: "Hide Balance",
      add: "Add Balance",
      withdraw: "Withdraw",
      moneyHistory: "Money Transaction History",
      goldHistory: "Gold Transaction History",
      deposit: "Deposit",
      purchase: "Purchase",
      sell: "Sell",
      seeAll: "See all",
      reset: " Reset",
      egp: "EGP",
      gram: "Gram",
      grams: "Grams",
      pending: "Pending",
      approved: "Approved",
      rejected: "Rejected",
      show: "Show Balance",
      refund: "Refund",
      storeFees: "Storage Fees",
      buy: "Buy",
      startDate: "Start Date",
      endDate: "End Date",
      noTransaction: " No Transactions Found",
      cancelationFees: "Cancelation Fees",
      transactionDetails: "Transaction Details",
      transactionStatus: " Transaction Status",
      transactionId: "Transaction ID",
      transactionDate: "Transaction Date",
      transactionType: "Transaction Type",
      transactionTime: "Transaction Time",
      goldPrice: "Gold Price",
      totalWeight: "Total Weight",
      price: "Price",
      manufacturingFees: "Manufacturing Fees",
      taxes: "Taxes",
      transactionFee: "Transaction Fee",
      storageFee: "Storage Fee",
      total: "Total",
      serialNumbers: "Serial Numbers",
      filter: "Filter",
      noTransactions: "No Transactions Found",
      noTransactionYet: "  No Transactions Yet",
      viewSerials: "View Serials",
      serialNumber: "Serial Numbers",
      noGoldTransactionsYet: "No Gold Transactions Yet",
    },
    dashboard: {
      totalAssets: "Total balance",
      totalAssetsToolTip: "This is the total balance you have in your wallet",
      available: "Available",
      availableToolTip:
        "This is the total amount of money you have in your wallet",
      goldAssets: "Gold Asset  ",
      goldAssetsToolTip:
        "This is the total amount of gold you have in your wallet",
      processing: "Processing",
      processingToolTip:
        "This is the total amount of money that is currently processing",
      lastUpdate: "Last Update at",
      gold24KAskPrice: "Gold 24K Buy Price",
      gold24KBuy: "Buy - 24K (Fees Excluded)",
      gold24KSell: "Sell - 24K (Fees Excluded)",
      gold24KBidPrice: "  Gold 24K Sell Price",
      title: "Dashboard",
      goldPriceChart: "Gold Price Chart",
      updated: "Gold price updated at",
      portfolio: "Portfolio",
    },
    buySell: {
      weightPlaceholder: "Enter Weight",
      moneyPlaceholder: "Enter Amount",
      totalWeight: "Total Weight",
      available: "Available",
      buy: "Buy",
      sell: "Sell",
      weight: "Weight",
      money: "Amount",
      sellAll: "Sell All",
      grams: "Grams",
      egp: "EGP",
      min: "Min",
      max: "Max",
      showBalance: "Show Balance",
      hideBalance: "Hide Balance",
      goldFractionCalculator: "Gold Fraction Calculator",
    },
    pinCode: {
      title: "Please enter the PIN code to proceed.",
      cancel: "Cancel",
      confirm: "Confirm",
      confirmPayment: "Confirm Payment",
      wrongPin: "Wrong PIN Code",
    },
    purchase: {
      confirmBuy: "Confirm Buy",
      confirmSell: "Confirm Sell",
      secureGold:
        "Your gold is securely stored in Central Bank of Egypt , Please be aware that delivery of the Gold Fractions is not available. ",
      sellTalk:
        "You must withdraw your money within 2 months after selling. If you don’t, it will be automatically moved to your bank account.",
      voucher: "Enter Voucher Code",
      successVoucher: "A voucher has been applied to your order",
      failedVoucher: "The voucher code you entered is invalid",
      goldPrice: "Gold Price",
      totalWeight: "Total Weight",
      valueOfGold: "Value of Gold",
      ManufacturingFee: "Manufacturing Fee",
      Taxes: "Taxes",
      TransactionFee: "Transaction Fee",
      StorageFee: "Storage Fee",
      total: "Total",
      Discount: "Discount Applied",
      Amount: "Amount Due",
      confirmPayment: "Confirm Payment",
      apply: "Apply",
      amountWeReceive: "Amount You’ll Receive",
      current: "Current Gold Price",
      totalWeightToSell: "Total weight to Sell ",
      yourPurchase: "Your Purchase was successful!",
      gramOfGold:
        "gram of gold you purchased has been added to your total gold balance.",
      portfolio: "Portfolio",
      yourGold: "Your gold has been sold !",
      yourGoldSale:
        "Your gold sale went through, and the funds have been added to your wallet.",
      wallet: "Wallet",
      viewTransaction: "View Transaction",
    },

    otp: {
      verify: "Verify Your Identity",
      enter:
        "Enter the OTP we sent to your phone or email to verify your account and complete the login process.",
      verifyButton: "Verify",
      receive: "Didn’t receive the code?",
      resend: "Resend code",
      wrong: "Wrong OTP",
      fill: "Please Fill All Fields",
      weSent:
        "We’ve sent a one-time passcode (OTP) to you. Please enter it below to verify your identity and proceed.",
      weSentLogin:
        "We’ve sent a one-time passcode (OTP) to you. Please enter it below to verify your identity and proceed.",
      weSentResetPassword:
        "We've sent a one-time passcode (OTP) to you. Enter it below to confirm your identity and reset your password.",
      set: "Set Up Your PIN Code",
      setPin:
        "You’re almost there! Set up a secure PIN code to protect your account and finish setting up.",
      confirmSet: "Confirm PIN Code",
      confirmPin:
        "Please re-enter your PIN code to confirm it and complete the setup.",
    },
    forgetPassword: {
      forgot: "Forgot Your Password?",
      just: `Just enter your email or phone number, and we'll send you an OTP to reset your password.`,
      phoneOrEmail: "Phone Number or E-mail",
      phoneOrEmailPlaceHolder: " Enter your phone number or email",
      phone: "Phone",
      email: "E-mail",
      send: "Send OTP",
    },
    login: {
      welcome: "Welcome back to",
      sabika: "Sabika",
      join: "Join our community and enjoy a",
      seamless: "seamless, Shariah-compliant experience.",
      phoneOrEmail: "Phone Number or E-mail",
      login: "Login",
      create: "Create Account",
      invalid: "Invalid Phon e Number or E-mail",
      buySell: "Buy & Sell Gold",
      fraction: "Fractions",
      track: "Track",
      Your: "Your Investment",
      portfolio: "Portfolio",
      secure: "Secure",
      Transactions: "Transactions & Wallet",
      Management: "Management",
      joinUs: "Join our community and enjoy a",
      for: "For A",
      seamless: "Seamless, Trusted & Shariah-compliant",
      Journey: "Journey",
      phone: "Phone",
      emailOrPhone: "E-mail or Phone",
      enter: "Enter Your",
      forget: "Forget Password?",
      first: "First name",
      enterFirst: "Enter your first name",
      last: "Last name",
      enterLast: "Enter your last name",
      email: "E-mail",
      emailPlaceholder: "name@example.com",
      phoneNumber: "Phone Number",
      phoneNumberPlaceHolder: "01xxxxxxxxx",
      referralCode: "Referral Code (Optional)",
      referralCodePlaceHolder: "Ge588w46",
      password: "Password",
      enterPassword: "Enter Your Password",
      confirmPassword: "Confirm Password",
      enterConfirmPassword: "Confirm Your Password",
      lower: "One Lowercase Letter.",
      upper: "One Uppercase Letter.",
      number: "One Number.",
      special: "One Special Character.",
      length: "At least 8 Characters.",
      matching: "Matching.",
      by: `By signing up, you're starting that you agree to our`,
      terms: "Terms and Conditions",
    },
    rightPage: {
      buy: "Buy & Sell",
      gold: "Gold.",
      anytime: "Anytime, Anywhere.",
      buySell: " Buy, sell, and manage your gold effortlessly with",
      sabika: `Sabika, the fastest and most secure way to grow your`,
      wealth: "wealth.",
      more: "More Value,",
      less: "Less Cost,",
      enjoy: "Enjoy the lowest manufacturing fees and maximize",
      your: " your returns.",
      flexible: "Flexible",
      payment: "Payments Methods.",
      manage: `Manage your wallet easily with multiple payment `,
      methods: "methods.",
      safety: "Your Safety,",
      priority: "Our Priority.",
      trusted: " Trusted and licensed by top institutions for secure",
      goldTrans: "gold transactions.",
      next: "Next",
      back: "Back",
    },
    errorMessage: {
      invalidPhone: "Invalid Phone Number",
      invalidEmail: "Invalid Email",
      invalidPassword: "Invalid Password",
      invalidConfirmPassword: "Invalid Confirm Password",
      invalidReferralCode: "Invalid Referral Code",
      invalidFirstName: "Invalid First Name",
      invalidLastName: "Invalid Last Name",
      invalidOTP: "Invalid OTP",
      invalidVoucher: "Invalid Voucher Code",
      invalidWeight: "Invalid Weight",
      invalidAmount: "Invalid Amount",
      invalidPin: "Invalid Pin",
      invalidSerial: "Invalid Serial Number",
      invalidStartDate: "Invalid Start Date",
    },
    resetPassword: {
      reset: "Reset Password",
      characters: `Your new password must be at least 8 characters long and should include a mix of uppercase and lowercase letters, numbers, and special symbols (e.g., !, @, #). Avoid using common words or easily guessed combinations.`,
      newPassword: "New Password",
      enterNewPassword: "Enter your new password",
      confirmPassword: "Confirm Password",
      enterConfirmPassword: "Confirm your password",
      least: "At least 8 characters",
      uppercase: "One uppercase letter",
      lowercase: "One lowercase letter",
      number: "One number",
      special: "One special character",
      matching: "Matching",
      save: "Save",
    },
    aboutUs: {
      About: "About",
      Sabika: "Sabika",
      PrivacyPolicy: "Privacy Policy",
      TermsAndConditions: "Terms And Conditions",
      faq: "Frequently Asked (FAQ)",
    },
  },
  ar: {
    resetPassword: {
      reset: "إعادة تعيين كلمة المرور",
      characters: `يجب أن تحتوي كلمة المرور الجديدة على 8 أحرف على الأقل، وأن تتضمن مزيجًا من الأحرف الكبيرة والصغيرة، الأرقام، والرموز الخاصة (مثل: !، @، #). تجنب استخدام الكلمات الشائعة أو التركيبات التي يسهل تخمينها.`,
      newPassword: "كلمة المرور الجديدة",
      enterNewPassword: "أدخل كلمة المرور الجديدة",
      confirmPassword: "تأكيد كلمة المرور",
      enterConfirmPassword: "أكد كلمة المرور الخاصة بك",
      least: "على الأقل 8 أحرف",
      uppercase: "حرف كبير واحد",
      lowercase: "حرف صغير واحد",
      number: "رقم واحد",
      special: "حرف خاص واحد",
      matching: "متطابق",
      save: "حفظ",
    },
    errorMessage: {
      invalidPhone: "يرجي إدخال رقم هاتف صالح مثل 01xxxxxxxxx",
      invalidEmail: "البريد الإلكتروني غير صحيح",
      invalidPassword: "كلمة المرور غير صحيحة",
      invalidConfirmPassword: "تأكيد كلمة المرور غير صحيح",
      invalidReferralCode: "رمز الدعوة غير صحيح",
      invalidFirstName: "الاسم الأول غير صحيح",
      invalidLastName: "الاسم الأخير غير صحيح",
      invalidOTP: "رمز التحقق غير صحيح",
      invalidVoucher: "رمز القسيمة غير صحيح",
      invalidWeight: "الوزن غير صحيح",
      invalidAmount: "المبلغ غير صحيح",
      invalidPin: "الرقم السري غير صحيح",
      invalidSerial: "الرقم المتسلسل غير صحيح",
      invalidStartDate: "تاريخ البدء غير صحيح",
    },
    login: {
      welcome: "مرحباً بك في",
      sabika: "سبيكة",
      join: "قم بتسجيل الدخول للوصول الي حسابك واستكشاف خدماتنا",
      phoneOrEmail: "رقم الهاتف أو البريد الإلكتروني",
      invalid: "رقم الهاتف أو البريد الإلكتروني غير صحيح",
      login: "تسجيل الدخول",
      create: "حساب جديد",

      buySell: "شراء وبيع الذهب",
      fraction: "جزيئات",
      track: "تتبع",
      Your: "استثمارك",
      portfolio: "الذهبي",
      secure: "آمن",
      Transactions: "المعاملات والمحفظة",
      Management: "الإدارة",
      joinUs: "انضم إلينا استمتع",
      for: "لتجربة",
      toSabika: "لسبيكة",
      seamless: "بتجربة سلسلة ومتوافقه مع الشريعه.",
      Journey: "الاستثمارية",
      email: "البريد الإلكتروني",
      phone: "الهاتف",
      emailOrPhone: "البريد الإلكتروني أو الهاتف",
      enter: "أدخل",
      password: "كلمة المرور",
      enterPassword: "أدخل كلمة المرور",
      forget: "نسيت كلمة المرور؟",
      first: "الاسم الأول",
      enterFirst: "أدخل الاسم الأول",
      last: "الاسم الأخير",
      enterLast: "أدخل الاسم الأخير",
      email: "البريد الإلكتروني",
      emailPlaceholder: "name@example.com",
      phoneNumber: "رقم الهاتف",
      phoneNumberPlaceHolder: " 01xxxxxxxxx",
      referralCode: " كود الدعوه (اختياري)",
      referralCodePlaceHolder: "Ge588w46",

      confirmPassword: "تأكيد كلمة المرور",
      enterConfirmPassword: "تأكيد كلمة المرور",
      lower: "حرف صغير واحد.",
      upper: "حرف كبير واحد.",
      number: "رقم واحد.",
      special: "حرف خاص واحد.",
      length: " على الأقل 8 أحرف.",
      matching: "متطابق.",
      by: `بالتسجيل، أنت توافق على`,
      terms: "الشروط والأحكام",
    },
    navbar: {
      home: "الرئيسيه",
      features: "المميزات",
      howitworks: "كيفية البدأ",
      download: "تحميل",
      signup: "تسجيل الدخول",
      lang: "English",
      Calculator: "حاسبة الذهب",
      login: "تسجيل الدخول",
    },
    section1: {
      unlock: "استكشف",
      potential: "إمكانات",
      gold: "الذهب",
      text: "تطبيقنا سهل الاستخدام يجعل الاستثمار في الذهب بسيطًا وآمنًا",
      text2:
        "قم بإدارة الذهب الخاص بك مباشرةً من منصتنا الآمنة وسهلة الاستخدام",
      download: "حمل التطبيق",
      StartYourJourney: "ابدأ رحلتك",
      goldPriceUpdate: "تم تحديث سعر الذهب بتاريخ",
      buy: "شراء - 24K ",
      sell: "بيع - 24K ",
      egp: "جنيه  ",
      goldBuy: "سعر شراء الذهب عيار 24",
      goldSell: "سعر بيع الذهب عيار 24",
    },
    section2: {
      why: "لماذا",
      sabika: "سبيكة ؟",
      buy: "قم بشراء وبيع",
      fraction: "جزيئات الذهب",
      GoldFractions: "أجزاء الذهب ",
      anytimeandplace: " في أي وقت ومن أي مكان",
      Accessgoldmarkets: "في سوق الذهب",
      withease: "استمتع بتجربة سلسة",
      Sabika: "سبيكة",
      track: "تتبع",
      your: "استثماراتك مباشرة",
      portfolio: "راقب استثماراتك بسهولة",
      special: "في أي وقت",
      enjoy: "تحكم",
      complete: "كامل",
      yourwallet: "في محفظتك",
      money: "أموالك",
      save: "حافظ على",
      safe: "آمنة",
      protected: "محمية",
      morePrivacy: "بأقصى درجات الأمان",
      easy: "وسهولة",
      management: " إدارة محفظتك ",
      enjoyA: "تمتع بتجربة ",
      with: "مع",
      seamless: "سهلة وسلسة",
    },
    section3: {
      More: "ترقب المزيد",
      Coming: "قريبًا",
      easyUse: "تجربة استخدام ",
      easy: "سهلة",
      Competitive: "تنافسية",
      Price: "أسعار",
      what: "ما",
      we: "نقدمه",
      offer: "لك",
      invest: "استثمر في الذهب",
      lowest: "بأقل سعر!",
      buy: " احصل على فرصة للدخول إلى عالم الذهب بأسعار  لا تُقارن.",
      prices: "بأسعار لا تقبل المنافسة",
      with: "مع رسوم الخدمة التنافسية لدينا.",
      enjoy: "استمتع",
      user: "بتجربة بسيطة",
      app: "وخالية من التعقيدات.",
      withsabika: "مع",
      sabika: "سبيكة,",
      makes: "استمتع بإدارة استثماراتك واستكشاف ميزات سبيكة بكل سهولة.",
    },
    section4: {
      Register: "سجّل الآن",
      RegDesc: " أنشئ حسابك بكل أمان على موقعنا وابدأ استخدام خدماتنا بسهولة",
      DepositFunds: "إضافة رصيد",
      DepositDesc: "أضف أموالك إلى محفظتك بطريقة آمنة و سهلة",
      ManageGold: "إدارة الذهب الخاص بك",
      ManageGoldDesc: "قم ببيع وشراء أجزاء الذهب بكل سهولة باستخدام حسابك.",
      how: "كيفية",
      start: "البدأ؟",
      download: "التنزيل",
      downloadText: "حمل تطبيق سبيكة من متجر التطبيقات الخاص بك",
      signup: "التسجيل",
      signupText: "أنشئ حسابًا وابدأ استثمارك في الذهب!",
      deposit: "أضف أموالاً إلى محفظتك",
      depositText: "حقق أهدافك المالية - أضف أموالاً واستثمر في الذهب",
      trade: "تداول الذهب",
      tradeText: "استمتع بتجربة تداول ذهب سهلة",
    },
    section5: {
      invest: "استثمر بذكاء مع ",
      sabika: "سبيكة",
      give: "امنح",
      future: "مستقبلك",
      financial: "المالي الاستقرار",
    },
    footer: {
      AboutUs: "عن سبيكة",
      faq: "الأسئلة الشائعة",
      privacy: "سياسة الاستخدام",
      Contactus: "تواصل معنا",
      terms: "الشروط والاحكام",
    },
    header: {
      wallet: "المحفظة",
      portfolio: "ذهبي",
      dashboard: "الرئيسية",
      account: "الحساب",
      language: "اللغة",
      logout: "تسجيل الخروج",
    },
    wallet: {
      total: "إجمالي الرصيد",
      totalToolTIp: "هذا هو الرصيد الإجمالي لمحفظتك",
      available: "متاح",
      availableToolTip: "هذا هو المبلغ الإجمالي للأموال التي لديك في محفظتك",
      availableWithDraw: "متاح للسحب",
      availableWithDrawToolTip: "هذا هو المبلغ الإجمالي الذي يمكنك سحبه",
      processing: "جاري التنفيذ",
      processingToolTip:
        "هذا هو المبلغ الإجمالي للأموال التي تتم معالجتها حاليًا",
      totalStorageFees: "إجمالي رسوم التخزين",
      totalStorageFeesToolTip:
        "هذا هو المبلغ الإجمالي للأموال التي قد دفعتها كرسوم تخزين",
      hide: "إخفاء الرصيد",
      add: "إيداع",
      withdraw: "سحب",
      noTransactions: "  لا توجد معاملات",
      moneyHistory: "سجل المعاملات المالية",
      goldHistory: " سجل المعاملات الذهبية",
      deposit: " إيداع",
      purchase: " شراء",
      sell: " بيع",
      seeAll: " اظهر الكل",
      egp: "جنيه  ",
      gram: "جرام",
      grams: "جرام",
      pending: "قيد المراجعة",
      approved: "معاملة ناجحة",
      rejected: "معاملة مرفوضة",
      show: "إظهار الرصيد",
      refund: "استرداد",
      storeFees: "رسوم التخزين",
      buy: "شراء",
      startDate: "تاريخ البدء",
      endDate: "تاريخ الانتهاء",
      reset: " إعادة تعيين",
      cancelationFees: "رسوم الإلغاء",
      transactionDetails: "تفاصيل المعاملة",
      transactionId: "رقم المعاملة",
      transactionDate: "تاريخ المعاملة",
      transactionType: "نوع المعاملة",
      transactionTime: "وقت المعاملة",
      goldPrice: "سعر الذهب",
      totalWeight: "إجمالي الوزن",
      price: "السعر",
      manufacturingFee: "رسوم المصنعية",
      taxes: "الرسوم الضريبية",
      transactionFee: "رسوم المعاملة",
      storageFee: "رسوم التخزين",
      total: "الإجمالي",
      serialNumbers: "أرقام التسلسل",
      filter: "تصفية",
      noTransaction: "لا توجد معاملات",
      noTransactionYet: "لا توجد معاملات بعد",
      transactionStatus: "حالة المعاملة",
      viewSerials: "عرض الأرفام المتسلسلة",
      serialNumber: "الأرقام المتسلسلة",
      noGoldTransactionsYet: "لا توجد معاملات ذهبية بعد",
    },
    dashboard: {
      totalAssets: "الرصيد الإجمالي",
      totalAssetsToolTip: "هذا هو إجمالي الرصيد التي لديك في محفظتك",
      available: "الرصيد المتاح",
      availableToolTip: "هذا هو إجمالي المبلغ المتوفر لديك في محفظتك",
      goldAssets: "ذهبي",
      goldAssetsToolTip: "هذا هو إجمالي كمية الذهب التي لديك في محفظتك",
      processing: "الرصيد قيد المراجعة",
      processingToolTip: "هذا هو إجمالي المبلغ الذي يتم معالجته حالياً",
      lastUpdate: "آخر تحديث في",
      gold24KAskPrice: "شراء 24 قيراط - بدون رسوم",
      gold24KBidPrice: "بيع 24 قيراط - بدون رسوم",
      title: "لوحة التحكم",
      updated: "آخر تحديث لسعر الذهب بتاريخ",
      goldPriceChart: "سعر الذهب",
      gold24KBuy: "شراء 24 قيراط - بدون رسوم",
      gold24KSell: "بيع 24 قيراط - بدون رسوم",
      portfolio: "ذهبي",
    },
    buySell: {
      totalWeight: "الذهب الخاص بك",
      available: "الرصيد المتاح",
      buy: "شراء",
      sell: "بيع",
      weight: "وزن",
      weightPlaceholder: "ادخل الوزن",
      moneyPlaceholder: "ادخل المبلغ",
      money: "المبلغ",
      sellAll: "بيع الكل",
      grams: "جرام",
      egp: "جنيه  ",
      min: "الحد الأدنى",
      minSell: "لا يمكن البيع بأقل من",
      max: "الحد الأقصى",
      showBalance: "إظهار الرصيد",
      hideBalance: "إخفاء الرصيد",
      goldFractionCalculator: "حاسبة الذهب ",
    },
    pinCode: {
      title: ".يرجي إدخال الرمز السري للمتابعة",
      cancel: "إلغاء",
      confirm: "تأكيد",
      confirmPayment: "تأكيد الدفع",
      wrongPin: "الرمز السري غير صحيح",
    },
    purchase: {
      confirmBuy: "تأكيد الشراء",
      confirmSell: "تأكيد البيع",
      secureGold:
        "يتم تخزين ذهبك بشكل آمن في البنك المركزي المصري.نود إعلامك بأن خدمة توصيل أجزاء الذهب غير متوفرة.",
      sellTalk:
        "يتوجب عليك سحب أموالك في غضون شهرين بعد البيع. وإلا، سيتم تحويلها تلقائيًا إلى حسابك المصرفي.",
      voucher: "أدخل كود الخصم",
      successVoucher: "تم تطبيق كود الخصم علي طلبك.",
      failedVoucher: "كود القسيمة الذي أدخلته غير صحيح",
      goldPrice: "سعر الذهب",
      totalWeight: "إجمالي الوزن",
      valueOfGold: "قيمة الذهب",
      manufacturingFees: "رسوم المصنعية",
      Taxes: "الضرائب",
      TransactionFee: "رسوم المعاملة",
      StorageFee: "رسوم التخزين",
      total: "الإجمالي",
      Discount: "الخصم  ",
      Amount: "المبلغ المستحق",
      confirmPayment: "تأكيد الدفع",
      apply: "أضف القسيمة",
      amountWeReceive: "المبلغ الذي ستستلمه",
      current: "سعر الذهب الحالي",
      totalWeightToSell: "إجمالي الوزن للبيع",
      yourPurchase: "!تهانينا! تم الشراء بنجاح",
      addedGold: "تم إضافة",
      gramOfGold: "جرام من الذهب الذي اشتريته إلي ذهبك",
      portfolio: "ذهبي",
      yourGold: "!تم بيع الذهب بنجاح",
      yourGoldSale: "تم بيع الذهب بنجاح, وتمت إضافة الأموال إلي محفظتك.",
      wallet: "عرض المحفظة",
      viewTransaction: "عرض المعاملة",
    },
    otp: {
      verify: "إدخال رمز التحقق",
      enter:
        "لقد أرسلنا إليك رمز تحقق لمرة واحدة (OTP).أدخل الرمز أدناه لتأكيد هويتك.",

      verifyButton: "تحقق",
      receive: " لم يصلك الرمز؟",
      resend: "أعد إرسال رمز التحقق",
      wrong: "رمز التحقق غير صحيح",
      fill: "يرجى ملء جميع الحقول",
      weSent: "لقد أرسلنا لك رمز تحقق لمرة واحدة (OTP).",
      weSentLogin:
        "لقد أرسلنا رمز تحقق لمرة واحدة (OTP) إليك. يرجى إدخاله أدناه لتأكيد هويتك والمتابعة.",
      set: "إعداد رمز الدخول الخاص بك",
      setPin:
        "أنت على بعد خطوة واحدة من الانتهاء! قم بإعداد رمز دخول آمن لحماية حسابك وإكمال الإعداد.",
      confirmSet: "تأكيد رمز الدخول",
      confirmPin:
        "يرجى إعادة إدخال رمز الدخول الخاص بك لتأكيده وإكمال الإعداد.",
      weSentResetPassword:
        "لقد أرسلنا إليك رمز تحقق لمرة واحدة (OTP).أدخل الرمز أدناه لتأكيد هويتك وإعادة تعيين كلمة المرور.",
    },
    forgetPassword: {
      forgot: "نسيت كلمة المرور؟",
      just: `قم بإدخال بريدك الإلكتروني أو رقم هاتفك، وسنرسل لك رمزًا لإعادة تعيين كلمة المرور.`,
      phoneOrEmail: "رقم الهاتف أو البريد الإلكتروني",
      phoneOrEmailPlaceHolder: "أدخل رقم هاتفك أو بريدك الإلكتروني",
      phone: "الهاتف",
      email: "البريد الإلكتروني",
      send: "إرسال رمز التحقق",
    },
    rightPage: {
      buy: "بيع وشراء الذهب",
      gold: "بسهولة",
      anytime: "في أي وقت وأي مكان.",
      buySell: "سبيكة تسهل عليك بيع, شراء, وإدارة الذهب , ",
      sabika: `الحل الأسرع والأكثر أمانًا للحفاز علي أموالك.     `,
      wealth: "أموالك.",
      more: "وفر أكثر,",
      less: "واستفد أكثر",
      enjoy: "احصل علي أقل رسوم مصنعيه وزود من ",
      your: " مكاسبك.",
      flexible: "تنوع في الدفع",
      payment: ", راحة في الإدارة",
      manage: `تحكم بمحفظتك بسهولة باستخدام عدة`,
      methods: "طرق دفع.",
      safety: "تعامل بثقة,",
      priority: "أمانك مضمون",
      trusted: "مرخصون وموثوقون من كبري المؤسسات لضمان أمان",
      goldTrans: "تعاملاتك الذهبية.",
      next: "التالي",
      back: "السابق",
    },
    aboutUs: {
      About: "عن",
      Sabika: "سبيكة",
      PrivacyPolicy: "الأمان والخصوصية",
      TermsAndConditions: "شروط الأستخدام",
    },
    // eg: {
    //   navbar: {
    //     home: "الرئيسيه",
    //     features: "المميزات",
    //     howitworks: "ابدأ ازاي",
    //     download: "تنزيل",
    //     signup: "تسجيل الدخول",
    //     login: "تسجيل الدخول",
    //     lang: "English",
    //   },
    //   section1: {
    //     unlock: "اكتشف",
    //     potential: "معانا",
    //     gold: "الدهب",
    //     text: "هنخلى الاستثمار فى الدهب ابسط و اسهل.",
    //     download: "نزل التطبيق",
    //     goldPriceUpdate: "تم تحديث سعر الذهب",
    //     buy: "شراء - 24K (بدون رسوم)",
    //     sell: "بيع - 24K (بدون رسوم)",
    //     egp: "جنيه مصري",
    //   },
    //   section2: {
    //     why: "ليه",
    //     sabika: "سبيكة ؟",
    //     buy: "بيع و اشتري",
    //     fraction: "أجزاء من الدهب.",
    //     track: "تقدر تتابع",
    //     your: "محفظة استثمارك.",
    //     buyWith: "اشتري بكل",
    //     safe: "أمان",
    //     easy: "واتحكم",
    //     management: "بمحفظتك.",
    //     enjoyA: "استمتع",
    //     witheasy: "بأسهل",
    //     seamless: "تجربة.",
    //   },
    //   section3: {
    //     what: "بنقدملك",
    //     we: "ايه؟",
    //     offer: "لك",
    //     invest: "استثمار في الدهب",
    //     lowest: "بأقل سعر!",
    //     lowest2: "بأقل سعر",
    //     buy: "هتشتري الدهب",
    //     prices: "بأسعار لا تقبل المنافسة",
    //     with: "مع رسوم متقبلش المنافسة.",
    //     enjoy: "أسهل تجربة",
    //     user: "بتجربة بسيطة",
    //     app: "بدون اي مشاكل",
    //     withsabika: "مع",
    //     sabika: "سبيكة,",
    //     makes: "هتقدر تشتري وتبيع بكل سهولة وتابع استثمارك علي طول.",
    //   },
    //   section4: {
    //     how: "ابدأ",
    //     start: "ازاي؟",
    //     download: "نزل",
    //     downloadText: "نزل سبيكة من متجر التطبيقات بتاعك",
    //     signup: "سجل",
    //     signupText: "اعمل حسابك وابدأ استثمارك في الدهب على طول",
    //     deposit: "ضيف فلوس لمحفظتك",
    //     depositText: "ضيف فلوس و ابدأ استثمار",
    //     trade: "اشترى وبيع",
    //     tradeText: "اشترى و بيع بكل سهولة",
    //   },
    //   section5: {
    //     invest: "استثمر بذكاء مع ",
    //     sabika: "سبيكة",
    //     give: "امنح",
    //     future: "مستقبلك",
    //     financial: "المالي الاستقرار",
    //   },
    //   footer: {
    //     privacy: "سياسة الاستخدام",
    //     terms: "الشروط والاحكام",
    //   },
    //   header: {
    //     wallet: "المحفظة",
    //     portfolio: "ذهبي",
    //     dashboard: "الرئيسية",
    //     account: "الحساب",
    //     language: "اللغة",
    //     logout: "تسجيل الخروج",
    //   },
    //   wallet: {
    //     total: "إجمالي الرصيد",
    //     totalToolTIp: "هذا هو الرصيد الإجمالي لمحفظتك",
    //     available: "متاح",
    //     availableToolTip: "هذا هو المبلغ الإجمالي للأموال التي لديك في محفظتك",
    //     availableWithDraw: "متاح للسحب",
    //     availableWithDrawToolTip: "هذا هو المبلغ الإجمالي الذي يمكنك سحبه",
    //     processing: "جاري التنفيذ",
    //     processingToolTip:
    //       "هذا هو المبلغ الإجمالي للأموال التي تتم معالجتها حاليًا",
    //     totalStorageFees: "إجمالي رسوم التخزين",
    //     totalStorageFeesToolTip:
    //       "هذا هو المبلغ الإجمالي للأموال التي قد دفعتها كرسوم تخزين",
    //     hide: "إخفاء الرصيد",
    //     add: "إضافة رصيد",
    //     withdraw: "سحب الرصيد",
    //     moneyHistory: "سجل المعاملات المالية",
    //     goldHistory: " سجل المعاملات الذهبية",
    //     deposit: " إيداع",
    //     purchase: " شراء",
    //     sell: " بيع",
    //     seeAll: " اظهر الكل",
    //     egp: "جنيه مصري",
    //     gram: "جرام",
    //     pending: "قيد الانتظار",
    //     approved: "تم الموافقة",
    //     rejected: "تم الرفض",
    //     show: "إظهار الرصيد",
    //     refund: "استرداد",
    //     storeFees: "رسوم التخزين",
    //     buy: "شراء",
    //     cancelationFees: "رسوم الإلغاء",
    //   },
    //   dashboard: {
    //     totalAssets: "إجمالي الأصول",
    //     totalAssetsToolTip: "هذا هو إجمالي الأصول التي لديك في محفظتك",
    //     available: "المتوفر",
    //     availableToolTip: "هذا هو إجمالي المبلغ المتوفر لديك في محفظتك",
    //     goldAssets: "أصول الذهب",
    //     goldAssetsToolTip: "هذا هو إجمالي كمية الذهب التي لديك في محفظتك",
    //     processing: "قيد المعالجة",
    //     processingToolTip: "هذا هو إجمالي المبلغ الذي يتم معالجته حالياً",
    //     lastUpdate: "آخر تحديث في",
    //     gold24KAskPrice: "سعر الذهب 24K",
    //     gold24KBidPrice: "سعر البيع 24K",
    //     title: "لوحة التحكم",
    //   },
    // },
  },
};

const useLanguage = create((set) => ({
  language:
    typeof window !== "undefined" && localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "en",
  translations: translations,
  setLanguage: (lang) => {
    if (typeof window !== "undefined") {
      localStorage.setItem("language", lang);
    }
    set({ language: lang });
  },
}));

export default useLanguage;
